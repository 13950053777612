import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../images/logo.png';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: #333;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease-in-out;

  @media (max-width: 475px) {
    padding: 1rem;
  }

  ${(props) => props.scrolled && `
    background: #222;
    padding: 0.5rem 2rem;
  `}
`;

const Logo = styled.img`
  height: ${(props) => (props.scrolled ? '50px' : '70px')};
  border-radius: ${(props) => (props.scrolled ? '50%' : '40% 0')};
  transition: all 0.3s ease-in-out;

  @media (max-width: 475px) {
    height: 50px;
    margin-right: 2rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;

  @media (max-width: 475px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 5rem;
  gap: 10px;

  @media (max-width: 475px) {
    display: none;
  }
`;

const AuditButton = styled.button`
  background: #ff6347;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background: #ff4500;
  }
`;

const DrawerIcon = styled(FaBars)`
  display: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 475px) {
    display: block;
  }
`;

const CloseIcon = styled(FaTimes)`
  display: block;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const Drawer = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background: #333;
  z-index: 10;
  padding: 1rem;

  a {
    display: block;
    color: white;
    padding: 0.5rem 0;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  button {
    width: 100%;
    background: #ff6347;
    color: white;
    border: none;
    padding: 0.5rem 0;
    margin-top: 1rem;
    cursor: pointer;
    &:hover {
      background: #ff4500;
    }
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const PopupContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const AuditForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Textarea = styled.textarea`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Prevent dragging to resize */
`;

const SubmitButton = styled.button`
  background: #ff6347;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background: #ff4500;
  }
`;

const SiteAuditPopup = ({ onClose }) => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    fetch("https://formspree.io/f/mqazbgnl", {
      method: "POST",
      body: new FormData(form),
      headers: {
        'Accept': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        setSubmitted(true);
      } else {
        alert('Something went wrong. Please try again.');
      }
    });
  };

  return (
    <PopupContainer>
      <PopupContent>
        <CloseButton onClick={onClose}>×</CloseButton>
        {!submitted ? (
          <>
            <h2>Free Site Audit</h2>
            <AuditForm onSubmit={handleSubmit}>
              <Input type="text" name="name" placeholder="Your Name" required />
              <Input type="email" name="email" placeholder="Your Email" required />
              <Input type="text" name="website" placeholder="Your Website URL" required />
              <Textarea name="requirements" placeholder="Explain Your Requirements" rows="5" required />
              <SubmitButton type="submit">Submit</SubmitButton>
            </AuditForm>
          </>
        ) : (
          <>
            <h2>Thank you! We will contact you soon.</h2>
            <SubmitButton onClick={onClose}>Close</SubmitButton>
          </>
        )}
      </PopupContent>
    </PopupContainer>
  );
};

const Header = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderContainer scrolled={scrolled}>
      <DrawerIcon onClick={() => setDrawerOpen(true)} />
      <Link to="/">
        <Logo src={logo} alt="1 Digit Technology" scrolled={scrolled} />
      </Link>
      <Nav>
        <NavLink to="/services">Services</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </Nav>
      <ContactInfo>
        <div>Call Us: +91-820-095-6950</div>
        <AuditButton onClick={() => setPopupOpen(true)}>Free Site Audit</AuditButton>
      </ContactInfo>
      {isPopupOpen && <SiteAuditPopup onClose={() => setPopupOpen(false)} />}
      <Drawer open={isDrawerOpen}>
        <CloseIcon onClick={() => setDrawerOpen(false)} />
        <NavLink to="/services" onClick={() => setDrawerOpen(false)}>Services</NavLink>
        <NavLink to="/about" onClick={() => setDrawerOpen(false)}>About</NavLink>
        <NavLink to="/contact" onClick={() => setDrawerOpen(false)}>Contact</NavLink>
        <button onClick={() => { setPopupOpen(true); setDrawerOpen(false); }}>Free Site Audit</button>
      </Drawer>
    </HeaderContainer>
  );
};

export default Header;
